@import './mixins';
@import './vars';

.NewsBox {
  width: 100%;
  border: 2px solid #000000;
  display: flex;
  flex-direction: column;
  @include comicx-shadow();
  img {
    grid-area: image;
    height: 278px;
    max-width: 100%;
    object-fit: cover;
  }
  .label {
    height: 190px;
    background-color: $white;
    padding: 20px;
    .title {
      width: 100%;
      height: 29px;
      grid-area: title;
      @include font($barlow, italic, 800, 28px, 29px);
      color: $red-blood;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .content {
      .text {
        width: 100%;
        grid-area: text;
        text-overflow: ellipsis;
        height: 47px;
        margin-bottom: 20px;
        @include font-architects();
        text-transform: uppercase;
  
        // overflow ellipsis in second line
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .button {
        grid-area: button;
        display: flex;
        justify-content: center;
        button {
          @include button(211px, 40px, $yellow-sun);
          @include comicx-shadow();
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    .label {
      // height: 220px;
      .title {
        // height: 59px;
        // overflow ellipsis in second line
      }
    }
  }
}

#Main {
  .label {
    height: 124px;
    background-color: #3D3D3D;
    color: $white;
    .title {
      color: $white;
    }
    .content {
      display: flex;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 650px) {
    .label {
      height: 190px;
      .content {
        display: unset;
        justify-content: unset;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .label {
      // height: 220px;
    }
  }
}