@import './mixins';
@import './vars';

.Menu {
  background-image: url('#{$cdn}/img/elements/bg-couple-red-1180-min.png');
  background-size: cover;
  background-position: center;
  min-width: 300px;
  .container {
    padding-top: 80px;
    padding-bottom: 313px;
    display: grid;
    grid-row-gap: 40px;
    grid-column-gap: 20px;
    grid-template-columns: 4fr 2fr 2fr 4fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      '. text text .'
      '. food drinks .'
    ;
    .text-graphic {
      grid-area: text;
      width: 100%;
      min-width: 185px;
    }
    .food {
      grid-area: food;
      button {
        @include button(auto, 100%, $yellow-sun);
        @include comicx-shadow();
        width: 100%;
        min-width: 180px;
      }
    }
    .drinks {
      grid-area: drinks;
      button {
        @include button(auto, 100%, $yellow-sun);
        @include comicx-shadow();
        width: 100%;
        min-width: 180px;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    background-position: top;
    .container {
      padding-bottom: 200px;
    }
  }
  @media screen and (max-width: 900px) {
    background-image: url('#{$cdn}/img/elements/bg-couple-red-760-min.png');
    background-position: top;
  }
  @media screen and (max-width: 500px) {
    background-image: url('#{$cdn}/img/elements/bg-couple-red-380-min.png');
    .container {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'text'
        'food'
        'drinks'
      ;
      padding-top: 40px;
      padding-bottom: 180px;
      .text-graphic {
        width: 85vw;
        min-width: 230px;
      }
      .food,
      .drinks {
        button {
          width: 85vw;
          min-width: 230px;
        }
      }
    }
  }
}