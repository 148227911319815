@import './mixins';
@import './vars';

.JoinUs {
  background-image: url('#{$cdn}/img/elements/bg-couple-red-1180-min.png');
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  .container {
    padding-top: 80px;
    padding-bottom: 111px;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 2fr 3fr 1fr 4fr 2fr;
    grid-template-rows: 120px 40px 101px 172px;
    grid-template-areas:
      '. graphic . . .'
      '. graphic . contact .'
      '. textbox . contact .'
      '. textbox . skills .'
    ;
    .text-graphic {
      grid-area: graphic;
    }
    .text-box {
      height: auto;
      grid-area: textbox;
      margin-top: 20px;
      margin-right: 7px;
      @include textbox();
      padding: 10px 13px;
      letter-spacing: 1px;
      @include comicx-shadow();
    }
    #text-oneline {
      display: none;
    }
    .contact-form {
      grid-area: contact;
      display: flex;
      flex-direction: column;
      input {
        @include input(100%, 30px, #fff, #000);
        margin-bottom: 10px;
        text-transform: unset;
      }
    }
    .name,
    .email,
    .phone{
      &.error{
        input{
          @include error-fields($yellow-sun);
        }
      }
    }
    .select{
      &.error{
        @include error-fields($yellow-sun);
      }
    }
    .skills {
      grid-area: skills;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      button {
        @include button(131px, 40px, $yellow-sun);
        @include comicx-shadow();
      }
      .title {
        @include font($barlow, italic, 800, 28px, 29px);
        color: white;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        min-width: 285px;
      }
      .select {
        display: grid;
        grid-row-gap: 10px;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: 100px 70px 81px;
        justify-content: space-between;
        div {
          @include font-architects();
          color: white;
          letter-spacing: 1px;
          text-transform: uppercase;
          * {
            cursor: pointer;
          }
        }
        label{
          // text-transform: capitalize;
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .container {
      grid-template-columns: 2fr 3fr 1fr 5fr 1fr;
    }
  }
  @media screen and (max-width: 800px) {
    background-image: url('#{$cdn}/img/elements/bg-couple-red-760-min.png');
    height: auto;
    .container {
      padding: 80px 75px 55px;
      grid-row-gap: 30px;
      grid-template-columns: 8fr;
      grid-template-rows: 160px auto 110px 162px;
      grid-template-areas:
        'graphic'
        'textbox'
        'contact'
        'skills'
      ;
      .text-graphic {
        margin: 0 auto;
      }
      .text-box {
        margin: 0;
      }
      #text-twoline {
        display: none;
      }
      #text-oneline {
        display: block;
      }
    }
  }
  @media screen and (max-width: 550px) {
    .container {
      padding: 40px 0 50px;
      grid-template-rows: 160px auto 110px 269px;
      .text-graphic {
        margin: 0;
      }
      .skills {
        .select {
          grid-template-rows: repeat(5, 1fr);
          grid-template-columns: auto;
          #other {
            grid-row: 5 / 6;
          }
        }
      }
    }
  }
}