@import './vars';

.News {
  background-image: url('#{$cdn}/img/elements/bg-tile-for-news.png');
  background-size: auto;
  background-repeat: repeat;
  .loading{
    text-align: center;
    padding: 200px;
    color: $red-blood;
    font-size: 50px;
  }
}