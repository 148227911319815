.PaperCrop {
  position: absolute;
  margin-top: -33px;
  height: 66px;
  width: 100%;
  // min-width: 1684px;
}

@media screen and (max-width: 800px) {
  .PaperCrop{
    height: 33px;
    margin-top: -17px;
  }
}