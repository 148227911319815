@import './mixins';
@import './vars';

.NewsDetailsPage {
  background-image: url('#{$cdn}/img/elements/bg-tile-for-news.png');
  background-size: auto;
  background-repeat: repeat;
  height: auto;
  width: 100%;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      '. image .'
      '. back .'
      '. content .'
    ;
    .image {
      grid-area: image;
      margin-top: 52px;
      margin-bottom: 10px;
      width: 100%;
      height: 420px;
      @include comicx-shadow();
    }
    .back{
      grid-area: back;
      a{
        text-decoration: none;
        @include font-architects();
        text-transform: uppercase;
        color: $red-blood;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .content{
      grid-area: content;
      height: auto;
      margin-bottom: 50px;
      .title {
        font-family: $barlow;
        font-style: italic;
        font-weight: 800;
        font-size: 28px;
        line-height: 29px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        color: #FF2929;
        margin-bottom: 10px;
      }
      .date {
        font-family: $barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        letter-spacing: 0.28px;
        color: #000000;
        margin-bottom: 10px;
      }
      .text {
        font-family: $architects;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #000000;
        white-space: pre-wrap;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .container {
      grid-template-columns: 1fr 10fr 1fr;
    }
  }
  @media screen and (max-width: 500px) {
    .container {
      grid-template-columns: auto;
      grid-template-areas:
        'image'
        'back'
        'content'
      ;
    }
  }
}