
// fonts
$barlow: 'Barlow Condensed';
$architects: 'Architects Daughter';


// colors
$blue-cancun: #25E5EC;
$blue-cancun-hover: #09BDC4;
$yellow-sun: #FFD200;
$yellow-sun-hover: #D3AE00;
$red-blood: red;
$white: white;

//url
$cdn: 'https://cdn.comicx.us';