@import './mixins';
@import './vars';

.NewsFeed {
  height: auto;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(3, 32.2%);
    grid-template-rows: auto;
    grid-auto-rows: 468px;
    padding-bottom: 70px;
  }
  @media screen and (max-width: 1020px) {
    .container {
      grid-template-columns: repeat(2, 48.8%);
      padding-left: 6%;
      padding-right: 6%;
    }
  }
  @media screen and (max-width: 760px) {
    .container {
      grid-template-columns: 100%;
      padding-left: 6%;
      padding-right: 6%;
    }
  }
  .loading {
    padding-top: 0 !important;
    padding-bottom: 70px !important;
  }
}