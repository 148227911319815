@import './mixins';
@import './vars';

.WhereSuperheroes {
  background-image: url('#{$cdn}/img/elements/bg-city-red-1180-min.png');
  background-size: cover;
  height: 616px;
  width: 100%;

  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 5fr 1fr 5fr;
    grid-template-rows: 160px 160px 50px 90px 157px;
    grid-template-areas:
      'box-white box-white . .'
      'box-white box-white . graphic'
      '. . . graphic'
      '. box-yellow box-yellow graphic'
      '. box-yellow box-yellow .'
    ;
  
    .text-graphic {
      grid-area: graphic;
    }
    
    .box-white{
      align-self: end;
      grid-area: box-white;
      @include textbox();
      letter-spacing: 1px;
      padding: 10px 15px;
      @include comicx-shadow();
    }
  
    .box-yellow{
      align-self: start;
      grid-area: box-yellow;
      @include textbox(auto, 100%, $yellow-sun);
      letter-spacing: 1px;
      padding: 10px 15px;
      @include comicx-shadow();
    }
  }
  @media screen and (max-width: 940px) {
    background-image: url('#{$cdn}/img/elements/bg-city-red-760-min.png');
    height: 785px;
    .container {
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 30px;
      grid-template-columns: 2fr 8fr 2fr;
      grid-template-rows: 342px 192px 191px;
      grid-template-areas:
        '. graphic .'
        '. box-white .'
        '. box-yellow .'
      ;
      .text-graphic {
        align-self: end;
      }
      .box-yellow {
        align-self: start;
      }
    }
  }
  @media screen and (max-width: 580px) {
    background-image: url('#{$cdn}/img/elements/bg-city-red-380-min.png');
    background-size: cover;
    height: auto;
    .container {
      padding-top: 30px;
      padding-bottom: 60px;
      grid-row-gap: 30px;
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'graphic'
        'box-white'
        'box-yellow'
      ;
      .text-graphic {
        justify-self: center;
        align-self: end;
        width: 100%;
        max-width: 320px;
      }
      .box-yellow {
        align-self: start;
      }
    }
  }
}