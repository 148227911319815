// fonts
@mixin font($family, $style, $weight, $size, $line-height) {
  font-family: $family, sans-serif;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}

@mixin font-barlow($size:18px, $line-height:24px){
  @include font($barlow, normal, normal, $size, $line-height);
}

@mixin font-architects($size:18px, $line-height:24px){
  @include font($architects, normal, normal, $size, $line-height);
}

// input for forms (white with black font architect)
@mixin input($width, $height, $background-color, $color){
  @include textbox($height, $width, $background-color, $color);
  &::placeholder{
    color: $color;
  }
}

@mixin textarea($width, $height, $background-color, $color){
  @include textbox($height, $width, $background-color, $color);
  resize: none;
  &::placeholder{
    color: $color;
  }
}

@mixin button($width, $height, $background-color, $color: #000){
  @include font-architects();
  @include textbox($height, $width, $background-color, $color);
  cursor: pointer;
  &:hover{
    @if ($background-color == $yellow-sun){
      background-color: $yellow-sun-hover;
    }
    @if ($background-color == $blue-cancun){
      background-color: $blue-cancun-hover;
    }
  }
}

// basic box
@mixin textbox($height: auto, $width: 100%, $background-color: #fff, $color: #000){
  @include font-architects();
  color: $color;
  padding: 3px 10px;
  background-color: $background-color;
  width: $width;
  height: $height;
  border: 2px solid #000000;
  box-sizing: border-box;
  text-transform: uppercase;
  border: 2px solid #000000;
}
// shadow
@mixin comicx-shadow {
  -webkit-box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.7);
  -moz-box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.7);
  box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.7);
}

@mixin error-fields($color: #FF2928){
  -webkit-box-shadow: 0px 0px 5px 4px $color;
  -moz-box-shadow: 0px 0px 5px 4px $color;
  box-shadow: 0px 0px 5px 4px $color;
}