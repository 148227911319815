@import './vars';
@import './mixins';

.MoreInfo {
  background-image: url('#{$cdn}/img/elements/bg-couple-black-1180-min.png');
  background-size: cover;
  height: 623px;
  width: 100%;
  .container {
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 5fr 1fr 5fr;
    grid-template-rows: 73px 29px 48px 30px 30px 30px 30px 30px 95px 40px auto;
    grid-template-areas:
      '. . . .'
      '. . . title '
      '. graphic . info'
      '. graphic . name'
      '. graphic . email'
      '. graphic . phone'
      '. graphic . event'
      '. graphic . calendar'
      '. graphic . comment'
      '. . . button'
    ;
    .text-graphic {
      grid-area: graphic;
      width: 100%;
      max-width: 480px;
    }
    .title {
      grid-area: title;
    }
    .info {
      grid-area: info;
    }
    .name {
      grid-area: name;
    }
    .email {
      grid-area: email;
    }
    .phone {
      grid-area: phone;
    }
    .event {
      grid-area: event;
    }
    .calendar {
      grid-area: calendar;
    }
    .comment {
      grid-area: comment;
    }
    .title{
      @include font($barlow, 'italic', 'bold', 28px, 29px);
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      width: 100%;
      max-width: 380px;
    }
    .info {
      @include font($architects, normal, normal, 18px, 24px);
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      width: 100%;
      max-width: 380px;
    }
    .name,
    .email,
    .phone,
    .event,
    .comment {
      &.error{
        input,
        textarea{
          @include error-fields();
        }
      }
      input {
        @include input(380px, 30px, #fff, #000);
        text-transform: unset;
        width: 100%;
        max-width: 380px;
      }
      textarea{
        @include textarea(380px, 95px, #fff, #000);
        text-transform: unset;
        width: 100%;
        max-width: 380px;
      }
    }
    
    .calendar {
      cursor: pointer !important;
      &.error{
        .react-datetime-picker{
          @include error-fields();
        }
      }
      position: relative;
      .calendar-placeholder{
        position: absolute;
        top: 0;
        width: 300px;
        .calendar-placeholder-inner{
          @include input(380px, 30px, #fff, #000);
          text-transform: unset;
          width: 300px;
          border-right: none;
        }
      }
      .calendar-input {
        max-width: 380px;
        input[name="cal"] {
          @include input(380px, 30px, #fff, #000);
          width: 100%;
          max-width: 380px;
          cursor: pointer;
          text-transform: unset;
        }
        // &:before {
        //   content: "";
        //   position: absolute;
        //   left: 351px;
        //   top: -3px;
        //   bottom: 0;
        //   width: 19px;
        //   background: url('#{$cdn}/img/elements/icon-calendar.svg') center / contain no-repeat;
        // }
      }
      .react-datetime-picker{
        @include input(380px, 30px, #fff, #000);
        width: 100%;
        max-width: 380px;
        .react-datetime-picker__wrapper{
          width: 100%;
          border: none;
          .react-datetime-picker__inputGroup{
            align-items: center;
            .react-datetime-picker__inputGroup__input{
              height: auto;
              padding: 0px 5px;
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero{
              padding-left: calc(1px + 0.54em);
            }
          }
          .react-datetime-picker__button{
            padding: 0 4px;
            .react-datetime-picker__calendar-button__icon {
              // display: none;
              // visibility: hidden;
            }
          }
        }
      }
    }
    .button-cont {
      grid-area: button;
      button{
        @include button(180px, 40px, $blue-cancun);
        @include comicx-shadow();
      }
    }
  }
  @media screen and (max-width: 1000px) {
    background-image: url('#{$cdn}/img/elements/bg-couple-black-760-min.png');
    background-size: cover;
  }
  @media screen and (max-width: 940px) {
    .container {
      display: grid;
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      grid-template-columns: 6fr 6fr;
      grid-template-rows: 112px 48px 30px 30px 30px 30px 30px 95px 40px auto;
      grid-template-areas:
        ' . title '
        'graphic info'
        'graphic name'
        'graphic email'
        'graphic phone'
        'graphic event'
        'graphic calendar'
        'graphic comment'
        '. button'
      ;
      .title {
        align-self: end;
      }
      // .calendar {
      //   .calendar-placeholder{
      //     .calendar-placeholder-inner{
      //       width: 225px;
      //     }
      //   }
      // }
    }
  }
  @media screen and (max-width: 580px) {
    height: 837px;
    background-image: url('#{$cdn}/img/elements/bg-couple-black-380-min.png');
    background-size: cover;
    background-position: 0 30%;
    .container {
      display: grid;
      justify-items: center;
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: 73px auto auto auto 30px 30px 30px 30px 30px 95px 40px auto;
      grid-template-areas:
        '.'
        'graphic'
        'title'
        'info'
        'name'
        'email'
        'phone'
        'event'
        'calendar'
        'comment'
        'button'
      ;
      .text-graphic {
        width: 100%;
        max-height: 200px;
        margin-bottom: 6px;
      }
      .title,
      .info {
        width: 100%;
        max-width: 380px;
      }
      .name,
      .email,
      .phone,
      .event,
      .calendar,
      .calendar-input,
      .comment {
        width: 100%;
        display: flex;
        justify-content: center;
        input {
          width: 100%;
          max-width: 380px;
        }
        textarea {
          width: 100%;
          max-width: 380px;
        }
      }
      .button-cont {
        width: 380px;
        button {
          width: 180px;
        }
      }
    }
  }
  @media screen and (max-width: 440px) {
    .container {
      .text-graphic {
        // min-width: 320px;
        max-height: 200px;
        margin-bottom: 6px;
      }
      .title,
      .info {
        // min-width: 320px;
        max-width: 380px;
      }
      .name,
      .email,
      .phone,
      .event,
      .calendar,
      .calendar-input,
      .comment {
        // width: 86vw;
        min-width: 210px;
        justify-self: start;
        input {
          width: 100%;
          max-width: 380px;
        }
        textarea {
          width: 100%;
          max-width: 380px;
        }
      }
      .button-cont {
        width: auto;
        justify-self: start;
        button {
          width: 180px;
        }
      }
    }
  }
}