@import './vars';
@import './mixins';

.FindUs {
  background-image: url('#{$cdn}/img/elements/bg-zoom-red-1180-min.png');
  background-size: cover;
  background-position: center;
  height: 628px;
  width: 100%;
  .container {
    height: 100%;
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 3fr 1fr 1fr 7fr;
    grid-template-rows: 1fr 170px 273px 1fr;
    grid-template-areas:
      '. . . .'
      'graphic . . map'
      'contact-info contact-info . map'
      '. . . .'
    ;
    .text-graphic {
      grid-area: graphic;
    }
    .contact-info{
      grid-area: contact-info;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .address {
        @include font($architects, normal, normal, 18px, 24px);
        @include textbox(94px, 100%, $yellow-sun);
        padding: 10px 50px 10px 15px;
        @include comicx-shadow();
      }
      .contact-list {
        @include font($architects, normal, normal, 18px, 24px);
        @include textbox(181px);
        @include comicx-shadow();
        padding: 20px 45px 17px 40px;
        margin-top: -1px;
        .contact-element{
          display: flex;
          align-items: center;
          &:not(:last-child){
            margin-bottom: 20px;
          }
          .contact-logo{
            font-size: 40px;
          }
          .contact-logos{
            font-size: 20px;
            &:nth-child(2){
              margin-left: 2px;
            }
          }
          .contact-value{
            margin-left: 20px;
          }
          .email{
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
    .map{
      grid-area: map;
      width: 100%;
      height: 100%;
      @include comicx-shadow();
    }
  }
  i {
    color: black;
  }
  @media screen and (max-width: 1150px) {
    height: 617px;
    .container {
      grid-template-areas:
        '. . . .'
        'graphic . . map'
        'contact-info contact-info contact-info map'
        '. . . .'
      ;
    }
  }
  @media screen and (max-width: 990px) {
    background-image: url('#{$cdn}/img/elements/bg-zoom-red-760-min.png');
    height: 942px;
    width: 100%;
    .container {
      height: 100%;
      display: grid;
      grid-row-gap: 50px;
      grid-column-gap: 40px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 273px 463px 1fr;
      grid-template-areas:
        '. .'
        'graphic contact-info'
        'map map'
        '. .'
      ;
      .text-graphic {
        grid-area: graphic;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    background-image: url('#{$cdn}/img/elements/bg-zoom-red-380-min.png');
    height: 1104px;
    width: 100%;
    .container {
      height: 100%;
      display: grid;
      grid-row-gap: 30px;
      grid-column-gap: 40px;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr 144px 273px 463px 1fr;
      grid-template-areas:
        '. .'
        'graphic .'
        'contact-info contact-info'
        'map map'
        '. .'
      ;
      .text-graphic {
        grid-area: graphic;
        width: 100%;
        max-width: 235px;
      }
    }
  }
  @media screen and (max-width: 392px) {
    .container {
      .contact-info {
        .address {
          font-size: 15px;
        }
      }
    }
  }
}