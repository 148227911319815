@import './vars';
@import './mixins';

.ThisIsWhere {
  background-image: url('#{$cdn}/img/elements/bg-pizza-1180-min.png');
  background-position: 53% 0;
  background-size: cover;
  height: 910px;
  width: 100%;

  .container {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 7fr 4fr 1fr;
    grid-template-rows: 140px 380px 70px 40px auto;
    grid-template-areas:
      '. . .'
      '. graphic .'
      '. textArea .'
      '. button .'
      '. . .'
    ;
  
    .text-graphic {
      grid-area: graphic;
      width: 100%;
      max-width: 380px;
      max-height: 380px;
    }
  
    .text-area {
      grid-area: textArea;
      @include font($architects, normal, normal, 18px, 24px);
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
    }
  
    .button-cont {
      grid-area: button;
      button{
        @include button(230px, 40px, $yellow-sun);
        @include comicx-shadow();
      }
    }
  }
  @media screen and (max-width: 1180px) {
    background-size: 140%;
    background-position: 53% 60%;
  }
  @media screen and (max-width: 1110px) {
    .container {
      display: grid;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      grid-template-columns: 7fr 5fr;
      grid-template-rows: 160px 380px 100px 40px auto;
      grid-template-areas:
        '. .'
        '. graphic'
        '. textArea'
        '. button'
        '. .'
      ;
      .text-area {
        align-self: end;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    background-size: 1600px;
    background-position: 68% 52%;
  }
  @media screen and (max-width: 900px) {
    .container {
      grid-template-columns: 5fr 7fr;
      .text-graphic {
        max-width: 380px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    background-image: url('#{$cdn}/img/elements/bg-pizza-380-min.png');
    background-size: 100% auto;
    background-position: 0 30%;
    height: auto;
    .container {
      display: grid;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      grid-template-columns: 4fr;
      grid-template-rows: 40px auto 114px auto 529px;
      grid-template-areas:
        '.'
        'graphic'
        'textArea'
        'button'
        '.'
      ;
    }
  }
  @media screen and (max-width: 395px) {
    background-size: cover;
    background-position: bottom;
  }
}