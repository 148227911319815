html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.container {
  max-width: 1180px;
  min-width: 300px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 1240px) {
    max-width: 100%;
    margin: 0 30px;
  }
}