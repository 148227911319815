@import './mixins';
@import './vars';

.NewsHeader {
  height: auto;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    grid-template-areas:'text-graphic NewsBox';
    .text-graphic {
      width: 100%;
      grid-area: text-graphic;
    }
    .text-title {
      display: none;
      @include font($barlow, italic, 800, 43px, 44px);
      color: $red-blood;
      text-transform: uppercase;
    }
    .NewsBox {
      width: 100%;
      grid-area: NewsBox;
      .title{
        max-width: 700px;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .container {
      grid-row-gap: 20px;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      padding: 50px 6%;
      grid-template-areas:
      'text-graphic'
      'NewsBox';
      .text-graphic {
        display: none;
      }
      .text-title {
        display: block;
      }
      .NewsBox {
        width: 100%;
        grid-area: NewsBox;
        .title{
          max-width: none;
        }
      }
    }
  }
}