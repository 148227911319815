@import './mixins';
@import './vars';

.WeBelieve {
  height: 877px;
  width: 100%;
  overflow: hidden;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 2fr 3fr 1fr 6fr;
    grid-template-rows: 80px 48px 452px 45px 60px 73px auto;
    grid-template-areas:
      '. . . .'
      '. . . box'
      'graphic graphic . box'
      '. . . box'
      '. . . .'
      'characters note note note'
      'characters . . .'
    ;
    .text-graphic {
      grid-area: graphic;
      width: 100%;
    }
    .box {
      grid-area: box;
      @include comicx-shadow();
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: auto;
      .box-text {
        @include textbox(auto, 100%, $yellow-sun);
        padding: 10px 15px;
        font-size: 18.7433px;
        line-height: 25px;
        letter-spacing: 1.04129px;
      }
      .box-image {
        background: url('#{$cdn}/img/elements/restaurant-min.png') no-repeat center;
        background-size: cover;
        width: 100%;
        height: auto;
        border: 2px solid #000000;
        margin-top: -1px;
      }
    }
    .note {
      grid-area: note;
      @include font-architects();
      letter-spacing: 1px;
      text-align: right;
      text-transform: uppercase;
    }
    .characters {
      grid-area: characters;
    }
  }
  @media screen and (max-width: 800px) {
    height: 1217px;
    .container {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 2fr 5fr 5fr;
      grid-template-rows: 80px 365px 545px 48px auto 60px;
      grid-template-areas:
        '. . .'
        'graphic graphic .'
        '. box box'
        '. . .'
        'note note note'
        '. . .'
      ;
      .characters {
        display: none;
      }
      .text-graphic {
        min-width: 250px;
      }
    }
  }
  @media screen and (max-width: 560px) {
    height: 1394px;
    .container {
      display: grid;
      grid-row-gap: 30px;
      grid-template-columns: auto;
      grid-template-rows: 30px 300px 648px auto 30px;
      grid-template-areas:
        '.'
        'graphic'
        'box'
        '.'
        'note'
      ;
      .characters {
        display: none;
      }
      .text-graphic {
        max-height: 300px;
      }
    }
  }
}