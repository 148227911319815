@import './vars';
@import './mixins';

.Footer {
  background-color: $white;
  height: auto;
  width: 100%;
  border-top: 4px solid $red-blood;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    padding-top: 40px;
    padding-bottom: 33px;
    .address {
      @include font($architects, normal, normal, 12px, 24px);
      text-transform: uppercase;
      width: 180px;
    }
    .contact-list {
      @include font($architects, normal, normal, 12px, 24px);
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000;
      height: auto;
      .contact-element {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .contact-logo {
          font-size: 20px;
        }
        .contact-value {
          margin-left: 38px;
        }
        .email{
          text-decoration: none;
          color: #000;
        }
      }
      .contact-social {
        i:nth-of-type(2n) {
          margin-left: 4px;
        }
        .contact-value {
          margin-left: 18px;
        }
      }
    }
    .bg-logo {
      margin-top: 5px;
      height: 63px;
      width: 313px;
    }
    .sm-logo {
      display: none;
      margin-top: 5px;
      height: 70px;
      width: 80px;
    }
  }
  i {
    color: black;
  }
  @media screen and (max-width: 800px) {
    .container {
      .bg-logo {
        display: none;
      }
      .sm-logo {
        display: block !important;
      }
    }
  }
  @media screen and (max-width: 530px) {
    .container {
      grid-row-gap: 30px;
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      .bg-logo {
        display: block;
      }
      .sm-logo {
        display: none !important;
      }
    }
  }
  @media screen and (max-width: 350px) {
    .container {
      .bg-logo {
        width: 100%;
      }
    }
  }
}