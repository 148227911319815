@import './mixins';
@import './vars';

.GrabBite {
  background-image: url('#{$cdn}/img/elements/bg-burger-1180-min.png');
  background-size: cover;
  height: 684px;
  width: 100%;
  .container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50px 405px 140px 40px auto;
    grid-template-areas:
      '.'
      'graphic'
      'textbox'
      'button'
      '.';
    .text-graphic {
      grid-area: graphic;
    }
    .text-box {
      grid-area: textbox;
      align-self: end;
      @include textbox(120px, 480px);
      padding: 10px 15px 14px;
      letter-spacing: 1px;
      @include comicx-shadow();
    }
    .button-cont {
      grid-area: button;
      button {
        @include button(189px, 40px, $yellow-sun);
        @include comicx-shadow();
      }
      margin-top: -1px;
    }
  }
  @media screen and (max-width: 1020px) {
    background-position: 35%;
  }
  @media screen and (max-width: 540px) {
    .container {
      .text-box {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 430px) {
    .container {
      .text-box {
        @include textbox(136px);
      }
    }
  }
  @media screen and (max-width: 300px) {
    background-position: center;
  }
}