@import './container';

header {
  position: absolute;
  z-index: 2;
  background: #2e2e2e;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  .navbar {
    height: 75px !important;
  }
  .container {
    height: auto;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 4fr 7fr 1fr;
    grid-template-rows: auto;
    .bg-logo {
      width: 280px;
      height: 55.71px;
      margin: auto 0;
    }
    .sm-logo {
      display: none;
      width: 62px;
      height: 53.72px;
      margin: auto 0;
      img{
        height: 100%;
      }
    }
    .nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div {
        > a{
          font-family: 'Barlow Condensed', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #ffffff;
          padding: 10px 15px;
          margin-right: 10px;
          border: 1px solid transparent;
          display: flex;
          text-align: center;
          text-decoration: none;
          &:hover {
            border: 1px solid #FFFFFF;
          }
          &.active {
            border: 1px solid #FFFFFF;
          }
        }
      }
    }
    .social {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      i {
        font-size: 24px;
        margin-left: 10px;
      }
    }
    .burger {
      cursor: pointer;
      display: none;
      justify-self: end;
      height: 42px;
      width: 57px;
      background-color: unset;
      margin: auto 0;
      padding: 0;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #FFFFFF;
      }
      img {
        margin: auto;
      }
    }
    .hidden {
      display: none;
    }
  }
  .menu {
    div {
      background: rgba(46, 46, 46, .93);
      > a {
        display: flex;
        align-items: center;
        height: 60px;
        width: 100%;
        font-weight: 800;
        font-family: 'Barlow Condensed', sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0;
        padding: 0;
        padding-left: 30px;
        margin-right: 10px;
        border: 1px solid transparent;
        border-top: 1px solid black;
        display: flex;
        text-align: center;
        text-decoration: none;
        &:hover {
          border: 1px solid #FFFFFF;
        }
        &.active {
          border: 1px solid #FFFFFF;
        }
      }
    }
  }
  i {
    color: white;
  }
  @media screen and (max-width: 1020px) {
    .container {
      grid-template-columns: 2fr 9fr 1fr;
      .bg-logo {
        display: none;
      }
      .sm-logo {
        display: block;
      }
    }
  }
  @media screen and (max-width: 810px) {
    background-color: transparent;
    .container {
      margin: 0;
      padding: 0 30px;
      background: #2e2e2e;
      grid-template-columns: 1fr 1fr;
      .nav,
      .social {
        display: none;
      }
      .burger {
        display: block;
      }
    }
  }
}

.header-margin {
  height: 75px;
}