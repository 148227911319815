@import './mixins';
@import './vars';

.ThisIsComicxHeader {
  background: url('#{$cdn}/img/elements/bg-ralph-1180-min.png') no-repeat center;
  background-size: cover;
  height: 473px;
  .container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 3fr 6fr 3fr;
    grid-template-rows: 126px 139px 209px;
    grid-template-areas:
      '. . .'
      '. graphic .'
      '. . .';
  
    .text-graphic {
      grid-area: graphic;
      width: 100%;
      min-width: 460px;
    }
  }
  @media screen and (max-width: 800px) {
    background: url('#{$cdn}/img/elements/bg-ralph-760-min.png') no-repeat center;
    background-size: cover;
  }
  @media screen and (max-width: 600px) {
    background: url('#{$cdn}/img/elements/bg-ralph-380-min.png') no-repeat center;
    background-size: cover;
    .container {
      grid-template-columns: auto;
      grid-template-rows: 126px 139px 209px;
      grid-template-areas:
        '.'
        'graphic'
        '.'
      ;
      .text-graphic {
        min-width: unset;
      }
    }
  }
}